/* Import Rajdhani font from Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;400;500;600;700&display=swap');

/* Define CSS variables for theming */
:root {
  /* Light theme colors */
  --light-bg-primary: #f0f0f0;
  --light-bg-secondary: #e0e0e0;
  --light-text-primary: #333333;
  --light-text-secondary: #666666;
  --light-accent: #a78bfa;
  --light-accent-hover: #9061f9;
  --light-border: #cccccc;

  /* Dark theme colors */
  --dark-bg-primary: #1a1a1a;
  --dark-bg-secondary: #2c2c2c;
  --dark-text-primary: #a78bfa;  /* Changed to purple */
  --dark-text-secondary: #c4b5fd;  /* Lighter purple for secondary text */
  --dark-accent: #a78bfa;
  --dark-accent-hover: #9061f9;
  --dark-border: #444444;

  /* Common variables */
  --border-radius: 8px;
  --box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  --transition: all 0.3s ease;
}

/* Base styles */
body {
  font-family: 'Rajdhani', sans-serif;
  margin: 0;
  padding: 0;
  transition: var(--transition);
}

/* Light theme styles */
body.light {
  background-color: var(--light-bg-primary);
  color: var(--light-text-primary);
}

/* Dark theme styles */
body.dark {
  background-color: var(--dark-bg-primary);
  color: var(--dark-text-primary);
}

/* Common component styles */
.card {
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  padding: 20px;
  margin-bottom: 20px;
  transition: var(--transition);
}

.light .card {
  background-color: var(--light-bg-secondary);
}

.dark .card {
  background-color: var(--dark-bg-secondary);
}

/* Button styles */
.button {
  border: none;
  border-radius: var(--border-radius);
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: var(--transition);
}

.light .button {
  background-color: var(--light-accent);
  color: var(--light-bg-primary);
}

.light .button:hover {
  background-color: var(--light-accent-hover);
  box-shadow: 0 0 10px var(--light-accent);
}

.dark .button {
  background-color: var(--dark-accent);
  color: var(--dark-bg-primary);
}

.dark .button:hover {
  background-color: var(--dark-accent-hover);
  box-shadow: 0 0 10px var(--dark-accent);
}

/* Input styles */
input[type="text"],
input[type="number"],
select,
textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: var(--border-radius);
  transition: var(--transition);
}

.light input[type="text"],
.light input[type="number"],
.light select,
.light textarea {
  border: 1px solid var(--light-border);
  background-color: var(--light-bg-secondary);
  color: var(--light-text-primary);
}

.dark input[type="text"],
.dark input[type="number"],
.dark select,
.dark textarea {
  border: 1px solid var(--dark-border);
  background-color: var(--dark-bg-secondary);
  color: var(--dark-text-primary);
}

input[type="text"]:focus,
input[type="number"]:focus,
select:focus,
textarea:focus {
  outline: none;
}

.light input[type="text"]:focus,
.light input[type="number"]:focus,
.light select:focus,
.light textarea:focus {
  box-shadow: 0 0 5px var(--light-accent);
}

.dark input[type="text"]:focus,
.dark input[type="number"]:focus,
.dark select:focus,
.dark textarea:focus {
  box-shadow: 0 0 5px var(--dark-accent);
}

/* Table styles */
table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  margin-bottom: 20px;
}

th, td {
  padding: 12px;
  text-align: left;
}

.light th,
.light td {
  border-bottom: 1px solid var(--light-border);
}

.dark th,
.dark td {
  border-bottom: 1px solid var(--dark-border);
}

th {
  font-weight: 600;
}

.light th {
  background-color: var(--light-accent);
  color: var(--light-bg-primary);
}

.dark th {
  background-color: var(--dark-accent);
  color: var(--dark-bg-primary);
}

tr:last-child td {
  border-bottom: none;
}

/* Sidebar styles */
.sidebar {
  background-color: var(--light-bg-secondary);
  transition: var(--transition);
}

.dark .sidebar {
  background-color: var(--dark-bg-secondary);
}

.nav-link {
  color: var(--light-text-primary);
  transition: var(--transition);
}

.dark .nav-link {
  color: var(--dark-text-primary);
}

.nav-link:hover,
.nav-link.active {
  background-color: var(--light-accent);
  color: var(--light-bg-primary);
}

.dark .nav-link:hover,
.dark .nav-link.active {
  background-color: var(--dark-accent);
  color: var(--dark-bg-primary);
}

/* Header styles */
.header {
  background-color: var(--light-bg-secondary);
  transition: var(--transition);
}

.dark .header {
  background-color: var(--dark-bg-secondary);
}

/* Responsive design */
@media (max-width: 768px) {
  .card {
    padding: 15px;
  }

  .button {
    padding: 8px 16px;
    font-size: 14px;
  }
}

/* Accessibility */
@media (prefers-reduced-motion: reduce) {
  * {
    transition: none !important;
  }
}

/* High contrast mode */
@media (prefers-contrast: high) {
  :root {
    --light-accent: #6200ee;
    --light-accent-hover: #3700b3;
    --dark-accent: #bb86fc;
    --dark-accent-hover: #3700b3;
  }
}

/* Print styles */
@media print {
  body {
    background-color: white;
    color: black;
  }

  .card {
    box-shadow: none;
    border: 1px solid #000;
  }

  .button {
    display: none;
  }
}