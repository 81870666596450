/* Footer.css */

.footer {
  grid-area: footer;
  background-color: var(--bg-secondary);
  color: var(--text-secondary);
  padding: 15px 20px;
  text-align: center;
  font-size: 0.9rem;
  border-top: 1px solid var(--border-color);
  transition: background-color 0.3s, color 0.3s;
}

.footer p {
  margin: 0;
}

/* Style for links in the footer, if any */
.footer a {
  color: var(--accent-color);
  text-decoration: none;
  transition: color 0.3s;
}

.footer a:hover {
  color: var(--accent-color-hover);
  text-decoration: underline;
}

/* Dark theme specific styles */
.dark-theme .footer {
  background-color: var(--dark-bg-secondary);
  color: var(--dark-text-secondary);
  border-top-color: var(--dark-border-color);
}

.dark-theme .footer a {
  color: var(--dark-accent-color);
}

.dark-theme .footer a:hover {
  color: var(--dark-accent-color-hover);
}

/* Responsive design */
@media (max-width: 768px) {
  .footer {
    padding: 10px 15px;
    font-size: 0.8rem;
  }
}

/* Print styles */
@media print {
  .footer {
    display: none;
  }
}

/* High contrast mode */
@media (prefers-contrast: high) {
  .footer {
    border-top-width: 2px;
  }
  
  .footer a {
    text-decoration: underline;
  }
}

/* Reduced motion */
@media (prefers-reduced-motion: reduce) {
  .footer,
  .footer a {
    transition: none;
  }
}