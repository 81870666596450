/* Airdrop.css */
.airdrop {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .airdrop h2 {
    margin-bottom: 20px;
    color: var(--text-primary);
  }
  
  .disclaimer {
    background-color: var(--bg-secondary);
    border: 1px solid var(--border-color);
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 20px;
    font-size: 0.9em;
    color: var(--text-secondary);
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group select,
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    background-color: var(--bg-secondary);
    color: var(--text-primary);
  }
  
  .form-group textarea {
    min-height: 100px;
    resize: vertical;
  }
  
  button {
    padding: 10px 20px;
    background-color: var(--accent-color);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  button:hover {
    background-color: var(--accent-color-hover);
  }
  
  button:disabled {
    background-color: var(--disabled-color);
    cursor: not-allowed;
  }
  
  .error {
    color: var(--error-color);
    margin-top: 10px;
  }
  
  .airdrop-result {
    margin-top: 20px;
  }
  
  .airdrop-result h3 {
    margin-bottom: 10px;
  }
  
  .airdrop-result table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .airdrop-result th,
  .airdrop-result td {
    padding: 10px;
    border: 1px solid var(--border-color);
    text-align: left;
  }
  
  .airdrop-result th {
    background-color: var(--bg-secondary);
    font-weight: bold;
  }
  
  @media (max-width: 600px) {
    .airdrop {
      padding: 10px;
    }
  
    .airdrop-result th,
    .airdrop-result td {
      padding: 8px;
      font-size: 14px;
    }
  }