/* Header.css */

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  background-color: var(--light-bg-secondary);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.dark-theme .header {
  background-color: var(--dark-bg-secondary);
  box-shadow: 0 2px 4px rgba(255, 255, 255, 0.1);
}

.logo {
  font-size: 24px;
  font-weight: 700;
  color: var(--light-accent);
  text-decoration: none;
  transition: var(--transition);
}

.logo:hover {
  text-shadow: 0 0 10px var(--light-accent);
}

.dark-theme .logo {
  color: var(--dark-accent);
}

.dark-theme .logo:hover {
  text-shadow: 0 0 10px var(--dark-accent);
}

.header-actions {
  display: flex;
  align-items: center;
}

.theme-toggle,
.connect-wallet {
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  margin-left: 15px;
  padding: 5px 10px;
  border-radius: var(--border-radius);
  transition: var(--transition);
}

.theme-toggle:hover,
.connect-wallet:hover {
  background-color: var(--light-accent);
  color: white;
}

.dark-theme .theme-toggle:hover,
.dark-theme .connect-wallet:hover {
  background-color: var(--dark-accent);
}

.account-info {
  font-size: 14px;
  color: var(--light-text-secondary);
  padding: 5px 10px;
  background-color: var(--light-bg-primary);
  border-radius: var(--border-radius);
}

.dark-theme .account-info {
  color: var(--dark-text-secondary);
  background-color: var(--dark-bg-primary);
}

@media (max-width: 768px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
  }

  .header-actions {
    margin-top: 10px;
  }
}