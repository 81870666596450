/* Dashboard.css */

.dashboard {
  padding: 20px;
}

.dashboard h1 {
  font-size: 32px;
  margin-bottom: 20px;
  color: var(--light-text-primary);
}

.dark-theme .dashboard h1 {
  color: var(--dark-text-primary);
}

.dashboard p {
  font-size: 18px;
  margin-bottom: 30px;
  color: var(--light-text-secondary);
}

.dark-theme .dashboard p {
  color: var(--dark-text-secondary);
}

.dashboard-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.dashboard .card {
  background-color: var(--light-bg-secondary);
  border-radius: var(--border-radius);
  padding: 20px;
  box-shadow: var(--box-shadow);
  transition: var(--transition);
}

.dark-theme .dashboard .card {
  background-color: var(--dark-bg-secondary);
}

.dashboard .card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.dark-theme .dashboard .card:hover {
  box-shadow: 0 10px 20px rgba(255, 255, 255, 0.1);
}

.dashboard .card h2 {
  font-size: 24px;
  margin-bottom: 15px;
  color: var(--light-accent);
}

.dark-theme .dashboard .card h2 {
  color: var(--dark-accent);
}

.dashboard .card p {
  font-size: 16px;
  margin-bottom: 20px;
}

.dashboard .card .button {
  display: inline-block;
  padding: 10px 20px;
  background-color: var(--light-accent);
  color: white;
  text-decoration: none;
  border-radius: var(--border-radius);
  transition: var(--transition);
}

.dark-theme .dashboard .card .button {
  background-color: var(--dark-accent);
}

.dashboard .card .button:hover {
  background-color: var(--light-accent-hover);
  transform: scale(1.05);
}

.dark-theme .dashboard .card .button:hover {
  background-color: var(--dark-accent-hover);
}