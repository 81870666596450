/* NFTCollection.css */
:root {
  --primary-color: #a78bfa;
  --primary-dark: #8b5cf6;
  --secondary-color: #4b5563;
  --background-color: #1f2937;
  --text-color: #f3f4f6;
  --card-background: #374151;
  --input-background: #4b5563;
  --border-color: #6b7280;
}

.nft-collection {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: Arial, sans-serif;
}

.nft-collection h2 {
  color: var(--primary-color);
  margin-bottom: 20px;
  text-align: center;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  color: var(--text-color);
}

.outlined-select,
.outlined-input {
  width: 100%;
  padding: 10px;
  border: 2px solid var(--border-color);
  border-radius: 4px;
  background-color: var(--input-background);
  color: var(--text-color);
  font-size: 16px;
}

button {
  width: 100%;
  padding: 12px;
  background-color: var(--primary-color);
  color: var(--text-color);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 16px;
  font-weight: bold;
}

button:hover:not(:disabled) {
  background-color: var(--primary-dark);
}

button:disabled {
  background-color: var(--secondary-color);
  cursor: not-allowed;
}

.error {
  color: #ef4444;
  margin-top: 10px;
  text-align: center;
}

.collection-info {
  background-color: var(--card-background);
  border-radius: 8px;
  padding: 15px;
  margin-top: 20px;
}

.collection-info h3 {
  color: var(--primary-color);
  margin-bottom: 10px;
}

.trait-filters {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
}

.trait-filter {
  flex: 1;
  min-width: 200px;
}

.nft-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.nft-card {
  background-color: var(--card-background);
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.3s;
}

.nft-card:hover {
  transform: translateY(-5px);
}

.nft-card img {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.nft-card h3 {
  font-size: 18px;
  margin: 10px;
  color: var(--primary-color);
}

.nft-card p {
  font-size: 14px;
  margin: 10px;
  color: var(--text-color);
}

.nft-card a {
  display: block;
  text-align: center;
  background-color: var(--primary-color);
  color: var(--text-color);
  text-decoration: none;
  padding: 10px;
  margin: 10px;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.nft-card a:hover {
  background-color: var(--primary-dark);
}

.traits {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  padding: 10px;
}

.trait {
  background-color: var(--secondary-color);
  color: var(--text-color);
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
}

@media (max-width: 768px) {
  .nft-grid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}