/* Sidebar.css */

.sidebar {
  width: 250px;
  height: 100%;
  background-color: var(--light-bg-secondary);
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.dark-theme .sidebar {
  background-color: var(--dark-bg-secondary);
  box-shadow: 2px 0 5px rgba(255, 255, 255, 0.1);
}

.nav-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.nav-item {
  margin-bottom: 10px;
}

.nav-link {
  display: block;
  padding: 10px 15px;
  color: var(--light-text-primary);
  text-decoration: none;
  border-radius: var(--border-radius);
  transition: var(--transition);
}

.dark-theme .nav-link {
  color: var(--dark-text-primary);
}

.nav-link:hover,
.nav-link.active {
  background-color: var(--light-accent);
  color: white;
}

.dark-theme .nav-link:hover,
.dark-theme .nav-link.active {
  background-color: var(--dark-accent);
}

@media (max-width: 768px) {
  .sidebar {
    width: 100%;
    height: auto;
    position: static;
    padding: 10px;
  }

  .nav-list {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }

  .nav-item {
    margin-bottom: 5px;
  }

  .nav-link {
    padding: 8px 12px;
    font-size: 14px;
  }
}