.app-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .main-content-wrapper {
    display: flex;
    flex: 1;
  }
  
  .sidebar {
    width: 250px;
    background-color: var(--bg-secondary);
    padding: 20px;
    overflow-y: auto;
  }
  
  .page-content {
    flex: 1;
    padding: 20px;
    overflow-y: auto;
  }
  
  header {
    background-color: var(--bg-secondary);
    padding: 15px 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  footer {
    background-color: var(--bg-secondary);
    padding: 10px 20px;
    text-align: center;
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    .main-content-wrapper {
      flex-direction: column;
    }
  
    .sidebar {
      width: 100%;
      order: 2;
    }
  
    .page-content {
      order: 1;
    }
  }
  
  /* Theme-specific styles */
  .light {
    --bg-primary: var(--light-bg-primary);
    --bg-secondary: var(--light-bg-secondary);
    --text-primary: var(--light-text-primary);
    --text-secondary: var(--light-text-secondary);
    --accent: var(--light-accent);
    --accent-hover: var(--light-accent-hover);
    --border: var(--light-border);
  }
  
  .dark {
    --bg-primary: var(--dark-bg-primary);
    --bg-secondary: var(--dark-bg-secondary);
    --text-primary: var(--dark-text-primary);
    --text-secondary: var(--dark-text-secondary);
    --accent: var(--dark-accent);
    --accent-hover: var(--dark-accent-hover);
    --border: var(--dark-border);
  }
  
  /* Additional component styles */
  .card {
    background-color: var(--bg-secondary);
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    padding: 20px;
    margin-bottom: 20px;
  }
  
  .button {
    background-color: var(--accent);
    color: var(--bg-primary);
    border: none;
    border-radius: var(--border-radius);
    padding: 10px 20px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: var(--transition);
  }
  
  .button:hover {
    background-color: var(--accent-hover);
  }