.token-gate {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

.token-gate h1 {
  font-size: 2.5em;
  color: var(--text-primary);
  margin-bottom: 20px;
}

.token-gate p {
  font-size: 1.2em;
  color: var(--text-secondary);
  margin-bottom: 30px;
}

.connect-button, .continue-button {
  background-color: var(--accent-color);
  color: white;
  border: none;
  padding: 12px 24px;
  font-size: 1.2em;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.connect-button:hover, .continue-button:hover {
  background-color: var(--accent-color-hover);
}

.token-check-results {
  background-color: var(--bg-secondary);
  border-radius: 8px;
  padding: 20px;
  margin-top: 30px;
}

.token-check-results h2 {
  font-size: 1.8em;
  color: var(--text-primary);
  margin-bottom: 20px;
}

.loading {
  font-size: 1.2em;
  color: var(--text-secondary);
  margin: 20px 0;
}

.token-check-results ul {
  list-style-type: none;
  padding: 0;
}

.token-check-results li {
  font-size: 1.1em;
  color: var(--text-primary);
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.overall-result {
  margin-top: 30px;
  padding: 20px;
  border-radius: 8px;
  font-size: 1.2em;
}

.overall-result.success {
  background-color: rgba(0, 255, 0, 0.1);
  color: #00c853;
}

.overall-result.failure {
  background-color: rgba(255, 0, 0, 0.1);
  color: #ff3d00;
}

.overall-result p {
  margin-bottom: 20px;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.token-check-results li span {
  animation: pulse 1.5s infinite;
}